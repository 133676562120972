<template>
    <div class="content-wrapper">
        <PageHeader screenName="Ver Unidades" :linkItems="linkItems" />

        <ConfirmModal :modalShow="modal.showModal" :typeModal="modal.typeModal" itemIdentifier="unidade" :infoText="{
            success: 'Essa ação dará permissão de uso à unidade.',
            warning: 'A unidade não terá mais permissão de uso.',
        }" @confirmed="changeStateUnidade" @canceled="cancelarAtivaDesativaUnidade" />

        <CustomAlert variant="danger" :message="alert.errorMessage" :show="alert.show" />

        <main class="card">
            <div class="pt-2 px-2" v-if="userPermissions.insert">
                <b-button @click="redirectToRegisterUnidade()" variant="primary-button">
                    <feather-icon icon="PlusIcon" class="mr-50" />
                    <span class="align-middle">Adicionar nova unidade</span>
                </b-button>
            </div>
            <b-row class="pt-1 px-2"  v-if="showDN">
                <b-col lg="12" md="12" sm="12">
                        <h2>Selecione um Departamento Regional</h2>
                </b-col>
                <b-col lg="4" md="6" sm="7">
                    <b-form-group label="Nome" label-for="departamentos-input">
                        <v-select
                        :disabled="loadingData"
                        id="departamentos"
                        :options="departamentosSelect"
                        v-model="departamento"
                        label="descricao"
                        index="id_departamento"
                        :reduce="(option => option.id_departamento)"
                        :filterable="true"
                        :clearable="false"
                        placeholder="Selecione um Dept.">
                            <span slot="no-options">Nenhuma opção selecionável.</span>
                        </v-select>
                    </b-form-group>
                </b-col>
                <b-col lg="4" md="6" sm="7" class="mt-2">
                    <b-button @click="filtrarUnidades()" variant="primary-button" :disabled="departamento == null">
                        <feather-icon icon="SearchIcon" class="mr-50" />
                        <span class="align-middle">Pesquisar</span>
                    </b-button>
                </b-col>
            </b-row>
            <hr>
            <TableFilter :filter="filter" @filterTable="loadDataUnidade" />
            <div v-if="loadingData" class="d-flex justify-content-center my-3">
                <div class="spinner-border text-custom-blue" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <section class="list-unidades" v-if="!loadingData">
                <b-table id="tableUnidade" responsive :stickyColumn="true" :busy.sync="filtering" :fields="table.fields"
                    :items="table.items" @sort-changed="ordemColumn" no-local-sorting :no-sort-reset="true">
                    <template #cell(descricao_departamento)="row">
                        <span class="font-weight-bold comprimento_maximo_celulas">
                            {{ row.item.descricao_departamento }}
                        </span>
                    </template>
                    <template #cell(descricao_unidade)="row">
                        <span class="font-weight-bold comprimento_maximo_celulas">
                            {{ row.item.descricao_unidade }}
                        </span>
                    </template>
                    <template #cell(descricao_regiao)="row">
                        <span class="font-weight-bold comprimento_maximo_celulas">
                            {{ row.item.descricao_regiao }}
                        </span>
                    </template>
                    <template #cell(ativo)="row">
                        <TableSwitch
                            :disabled="!canChangeStatus"
                            :checked="row.item.ativo" 
                            :description="row.item.descricao_unidade"
                            :itemId="row.item.id_unidade"
                            @stateModified="openModalConfirm"
                        />
                    </template>
                    <template #cell(actions)="row">
                        <feather-icon
                            @click.prevent="redirectToView(row.item)"
                            id="view-unidade"
                            role="button"
                            class="text-custom-blue mr-1"
                            icon="EyeIcon"
                            size="18"
                            v-b-tooltip.hover.html 
                            title="<b>Detalhes da Unidade</b>" 
                        />
                        <feather-icon
                            v-if="userPermissions.edit"
                            @click.prevent="redirectToEdit(row.item)"
                            id="edit-departamento"
                            role="button"
                            class="text-custom-blue"
                            icon="EditIcon"
                            size="18"
                            v-b-tooltip.hover.html 
                            title="<b>Editar Unidade</b>"
                        />
                    </template>
                </b-table>
                <div class="pb-2 px-1">
                    <span v-if="withoutUnidade" class="ml-1">Nenhuma unidade encontrada</span>
                    <b-col sm="12" class="mt-2">
                        <CustomPagination :paginacao="paginationData" @page-cliked="updateCurrentPage" />
                    </b-col>
                </div>
            </section>
        </main>
    </div>
</template>

<script>
import {actions, subjects} from "@/libs/acl/rules";
import { BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BTable } from 'bootstrap-vue';
import vSelect from 'vue-select';
import PageHeader from '@/views/components/custom/page-header/PageHeader.vue';
import CustomPagination from '@/views/components/custom/pagination/CustomPagination.vue';
import TableSwitch from '@/views/components/custom/table-switch/TableSwitch.vue';
import TableFilter from '@/views/components/custom/table-filter-section/TableFilter.vue';
import ConfirmModal from '@/views/components/custom/modals/ConfirmModal.vue';
import TimedAlert from '@/views/components/custom/timed-alert/TimedAlert.vue';
import CustomAlert from "@/views/components/custom/timed-alert/CustomAlert.vue";
import { getIdDepartamento } from '@core/utils/store/getStore';
import { setIdDepartamento } from "@core/utils/store/setStore";
import { getListFilter } from '@core/utils/store/getStore';
import { setListFilter } from '@core/utils/store/setStore';
export default {
    title: 'Unidades',

    components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BTable,
    PageHeader,
    vSelect,
    CustomPagination,
    TableSwitch,
    ConfirmModal,
    TimedAlert,
    TableFilter,
    CustomAlert
},

    data() {
        return {
            linkItems: [
                {
                    name: 'Unidades Operacionais',
                    active: true
                }
            ],
            showDN: this.$can(actions.VISUALIZAR, subjects.UNIDADE_DN),
            canChangeStatus: this.$can(actions.ATUALIZAR_STATUS, subjects.UNIDADE),
            loadingData: true,
            filtering: false,
            departamentosSelect: [],
            departamento:null,
            search:'',
            filter: false,
            withoutUnidade: false,
            canEdit: false,
            canInsert: false,
            table: {
                fields: [
                    { key: 'descricao_departamento', sortable: false, label: 'DR', class: `${this.$can(actions.VISUALIZAR, subjects.UNIDADE_DN) ? '' : 'd-none'}`},
                    { key: 'descricao_unidade', sortable: true, label: 'Unidade Operacional', visible:true },
                    { key: 'descricao_regiao', sortable: true, label: 'Região' ,visible:true  },
                    { key: 'ativo', sortable: true, label: 'status' ,visible:true  },
                    { key: 'actions', label: 'ações', sortable: false, class: 'mw-150 text-center col-1', visible:true  }
                ],
                items: [],
                optionsSize: [10, 25, 50, 100],
                columnOrder: 'descricao_unidade',
                order: 'asc'
            },
            paginationData: {
                currentPage: 1,
                totalLines: 0,
                fromLine: 0,
                toLine: 0,
                defaultSize: 10,
            },
            itemDesativar: null,
            modal: {
                showModal: false,
                typeModal: 'success',
            },
            alert: {
                errorMessage: '',
                show: false
            },
            userPermissions: {
                edit: false,
                insert: false
            },
            filterSearch: null,
            dnUser: this.$can(actions.VISUALIZAR, subjects.UNIDADE_DN)
        }
    },

    mounted() {
        this.setUserPermissions(this.$store.state.sessions.userData.ability);
        if(this.dnUser){
            this.loadDepartamento()
    
            this.filterSearch = getListFilter() ?? null
    
            if(this.filterSearch.departamento && this.filterSearch.route == this.$router.currentRoute.name){
                this.departamento  = this.filterSearch.departamento.id_departamento
            }else{
                this.departamento = getIdDepartamento();
            }
            
            if(this.departamento){
                this.filtrarUnidades();
            }
        }else{
            this.loadDataUnidade();
        }
    },

    methods: {
        loadDepartamento() {
            this.$http.get(this.$api.departamento()).then(({ data }) => {
                this.departamentosSelect.push(...data)
            })
        },

        loadDataUnidade(filterData = null, departamento = null) {
            this.paginationData.defaultSize = filterData !== null ? filterData.defaultSize : 10;
            if(filterData?.hasOwnProperty('currentPage')) { this.paginationData.currentPage = filterData.currentPage; }
         
            const idDepartamento = this.filterSearch?.departamento?.id_departamento ?? this.departamento 
     
            const parameters = {
                termoPesquisa: filterData ? filterData.searchTerm : '',
                tamanho: this.paginationData.defaultSize,
                pagina: this.paginationData.currentPage,
                colunaOrdenar: this.table.columnOrder,
                ordem: this.table.order,
                id_departamento: departamento ?? idDepartamento
            };

            this.filtering = true;

            if((this.showDN && parameters.id_departamento) || !this.showDN) {
                this.$http.get(this.$api.unidade(), { params: parameters }).then(({ data }) => {
        
                    this.table.items = data.data;
    
                    this.withoutUnidade = false;
                    if (data.data.length == 0) {
                        this.withoutUnidade = true;
                    }
    
                    this.loadingData = false;
                    this.filtering = false;
    
                    this.startPagination(data)
                })
            }

            this.loadingData = false;
        },

        startPagination(data) {
            this.paginationData.currentPage = data.current_page;
            this.paginationData.totalLines = data.total;
            this.paginationData.fromLine = data.from;
            this.paginationData.toLine = data.to;
        },

        ordemColumn(sort) {
            this.table.columnOrder = sort.sortBy;
            this.table.order = sort.sortDesc ? 'desc' : 'asc';

            this.filtering = true;
            this.filter = !this.filter;
        },

        updateCurrentPage(page) {
            this.paginationData.currentPage = page;
            this.filtering = true;
            this.filter = !this.filter;
        },

        openModalConfirm(value) {
            this.modal.typeModal = 'warning';

            if (value.state) {
                this.modal.typeModal = 'success';
            }

            this.$helpers.changeStateActiveTableRow(this.table.items, value, 'id_unidade');

            this.itemDesativar = value;
            this.modal.showModal = true;
        },

        changeStateUnidade() {
            this.$http.patch(this.$api.unidadeParameter(this.itemDesativar.itemId)).then(() => {
                this.modal.showModal = false;
                this.alert.show = false;
            }).catch(({ response: { data } }) => {
                this.modal.showModal = false;

                this.$helpers.backStateActiveTableRow(this.table.items, this.itemDesativar, 'id_unidade');

                this.alert.errorMessage = data.errors;
                this.alert.show = data.errors ? true : false;
            });
        },

        redirectToView(item) {
       
            this.redirectWithParameters('unidade-view', item);
        },

        redirectToEdit(item) {
            this.redirectWithParameters('unidade-edit', item);
        },

        redirectWithParameters(routeName, { id_unidade, descricao_unidade }) {
            this.$router.push(
                {
                    name: routeName,
                    params: {
                        id_unidade, 
                        acronym: descricao_unidade
                    }
                }
            );
        },

        cancelarAtivaDesativaUnidade() {
            this.$helpers.backStateActiveTableRow(this.table.items, this.itemDesativar, 'id_unidade');

            this.modal.showModal = false;
        },

        redirectToRegisterUnidade() {
            this.$router.push({ name: 'unidade-register' });
        },

        filtrarUnidades(){
         
           this.loadDataUnidade(null,this.departamento)
        },

        setUserPermissions(permissions) {
            const editPermissions = permissions.filter(state => {
                return (
                    (state.action === actions.RESPONSAVEL_ATUALIZAR && state.subject === subjects.UNIDADE_DR) ||
                    (state.action === actions.RESPONSAVEL_ATUALIZAR && state.subject === subjects.UNIDADE_DN) ||
                    (state.action === actions.ATUALIZAR && state.subject === subjects.UNIDADE)
                );
            });

            const insertPermissions = permissions.filter(state => {
                return (
                    state.action === actions.INSERIR && state.subject === subjects.UNIDADE
                )
            })

            this.userPermissions = {
                edit: editPermissions.length > 0,
                insert: insertPermissions.length > 0
            }
        },
    },
    watch: {
        departamento(val){
            const param = {
                route: this.$router.currentRoute.name,
                departamento: val
            }
            if(val){
                setIdDepartamento(val);
                setListFilter(param)
                this.filtrarUnidades();
            }
        }
    }
}
</script>

<style>
.comprimento_maximo_celulas {
    max-width: 240px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    white-space: nowrap;
}

.comprimento_status {
    width: 100px;
}

.hidden_overflow_tabela {
    overflow: hidden;
}
</style>